import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Collapse } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { parseDateFormat, requestStatus } from "gsi-ui-components";
import { getNewFeatures } from "../../actions/features";
import "./NewFeaturesList.less";

const { Panel } = Collapse;

const NewFeaturesList = ({ getNewFeatures, featuresInfo, featuresStatus }) => {
  useEffect(() => {
    getNewFeatures();
  }, [getNewFeatures]);

  const showNewFeatures =
    featuresStatus === requestStatus.SUCCESS && !isEmpty(featuresInfo);

  return (
    showNewFeatures && (
      <div className="new-features-container">
        <p className="latest-version">
          Latest version {parseDateFormat(featuresInfo.releaseDate)} (
          {featuresInfo.version})
        </p>
        <Collapse ghost expandIconPosition="right">
          <Panel
            header={`See what is improved in version ${featuresInfo.version}`}
          >
            {featuresInfo.changeLog.map(feature => (
              <div className="collapse-item" key={feature}>
                <CheckCircleFilled style={{ color: "green" }} />
                <p>{feature}</p>
              </div>
            ))}
          </Panel>
        </Collapse>
      </div>
    )
  );
};

NewFeaturesList.propTypes = {
  getNewFeatures: PropTypes.func,
  featuresInfo: PropTypes.object,
  featuresStatus: PropTypes.string
};

const mapStateToProps = state => {
  return {
    featuresInfo: state.features?.featuresInfo,
    featuresStatus: state.features?.status
  };
};

export default React.memo(
  connect(mapStateToProps, { getNewFeatures })(NewFeaturesList)
);
