import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { capitalize, isEmpty } from "lodash";
import {
  CloseCircleFilled,
  ExclamationCircleOutlined,
  LoadingOutlined,
  CopyOutlined
} from "@ant-design/icons";
import { Layout, Spin, Tag, Tooltip, message } from "antd";
import {
  InfoCard,
  InputCell,
  WarningDisclaimer,
  SurgeryPlanningInfo,
  BaseInfoDetail,
  calculateAge,
  formatDate,
  parseDateFormat,
  checkValue,
  inputType,
  REQUEST_STATUS,
  genders,
  parseDateFormatFullYear,
  PORTAL_LOCATION_PATH,
  copyToClipboard,
  requestStatus
} from "gsi-ui-components";
import Header from "../layout/Header";
import {
  getCaseInfo,
  clearCaseInfo,
  getPlanningInfo,
  getCTScanPreview,
  setCTScanPreview,
  setCTScanPreviewError,
  resetCTScanPreviewStore,
  loadImplantsInfo,
  clearPlanningInfo
} from "../../actions/cases";
import { getCaseTicketList } from "../../actions/tickets";
import { getSharedByInfo } from "../../actions/sharedCaseInfo";
import { CASE_DETAILS_SECTIONS } from "../../helpers/consts";
import {
  checkIsLastFileDownloaded,
  checkIsLastFileManualUpload,
  loadCTScansPreview,
  selectActiveTab
} from "../../helpers/caseHelper";
import { websocketClearMessage } from "../../actions/websocket";
import { updateTicketJiraInfo } from "./CaseDetailsHelper";
import PoweredByLogo from "../../resources/layout/SI-byProVoyance-logo.png";
import CaseStatusCard from "./cards/CaseStatusCard";
import CaseStageCard from "./cards/CaseStageCard";
import CtScansCard from "./cards/CtScansCard";
import { ReactComponent as SharedCase } from "../../resources/shareCases/SharedCase.svg";
import { ReactComponent as ExitIcon } from "../../resources/newcase/ExitIcon.svg";
import "./CaseDetails.less";

const { Content } = Layout;

const getCloseButton = historyValue => {
  return (
    <Tooltip title="Exit">
      <ExitIcon
        className="exit-button"
        onClick={() => historyValue.push(PORTAL_LOCATION_PATH.SURGERY_CASES)}
      />
    </Tooltip>
  );
};

const getSurgeryDateValue = (date, isDeclined) => {
  return (
    <div className="card-body-container">
      <InputCell
        label="Surgery day selected"
        type={inputType.DATEPICKER}
        config={{
          placeholder: parseDateFormatFullYear(date) || "Select date",
          disabled: true
        }}
      />
      <InputCell
        className="surgery-date-picker"
        type={inputType.CHECKBOX}
        config={{
          label: "Surgery declined",
          checked: isDeclined,
          disabled: true
        }}
      />
    </div>
  );
};

const getPatientValue = (name, mrn, gender, birthdate, age) => {
  return (
    <div className="card-body-container">
      <InputCell
        label="Name"
        type={inputType.TEXTINPUT}
        config={{
          placeholder: "E.g. John Smith",
          value: name,
          maxlength: 70,
          disabled: true
        }}
      />
      <InputCell
        label="MRN"
        type={inputType.TEXTINPUT}
        config={{
          placeholder: "Medical Record Number",
          value: mrn,
          maxlength: 25,
          disabled: true
        }}
      />
      <InputCell
        label="Gender"
        type={inputType.SELECT}
        config={{
          value: gender,
          disabled: true,
          options: genders
        }}
      />
      <InputCell
        label="Date of birth"
        type={inputType.DATEPICKER}
        config={{
          placeholder: "Select date",
          value: birthdate,
          disabled: true
        }}
      />
      <InputCell
        label="Age"
        type={inputType.TEXTINPUT}
        config={{
          placeholder: "-",
          disabled: true,
          value: isNaN(age) ? "" : age
        }}
      />
    </div>
  );
};

const getSharedByValue = (name, email) => {
  return (
    <div className="card-body-container">
      <InputCell
        label="Name"
        type={inputType.TEXTINPUT}
        config={{
          value: name,
          maxlength: 70,
          disabled: true
        }}
      />
      <InputCell
        className="user-email-input"
        label={
          <div>
            Email
            <Tooltip title="Copy">
              <CopyOutlined
                onClick={async () => {
                  await copyToClipboard(email, "Email", message);
                }}
              />
            </Tooltip>
          </div>
        }
        type={inputType.TEXTINPUT}
        config={{
          value: email,
          maxlength: 70,
          disabled: true
        }}
      />
    </div>
  );
};

const getSurgeryValue = (operationSide, classification) => {
  return (
    <div className="card-body-container">
      <InputCell
        label="Operation side"
        type={inputType.RADIO}
        config={{
          value: operationSide || "",
          options: [
            { value: "RIGHT", label: "Right" },
            { value: "LEFT", label: "Left" }
          ],
          disabled: true
        }}
      />
      <InputCell
        label="Comment"
        type={inputType.TEXTINPUT}
        config={{
          placeholder: "E.g. B2, C2, E2",
          maxlength: 25,
          value: classification || "",
          disabled: true
        }}
      />
    </div>
  );
};

const getCtValue = (ctScansPreview, ctScansStatus) => {
  return (
    <CtScansCard
      ctScansPreview={ctScansPreview}
      ctScansStatus={ctScansStatus}
    />
  );
};

const getNotesValue = surgeryNote => {
  return (
    <InputCell
      type={inputType.TEXTAREA}
      config={{
        minRows: 3,
        maxRows: 6,
        maxlength: 255,
        placeholder: "Add a description here",
        disabled: true,
        value: surgeryNote
      }}
    />
  );
};

const getDefaultValue = values => {
  return (
    <div className="card-body-container">
      {Object.keys(values).map(item => (
        <BaseInfoDetail
          key={item}
          title={item}
          data={checkValue(values[item])}
        />
      ))}
    </div>
  );
};

const getPlanningValue = (
  data,
  surgeryMeasurements,
  operationSide,
  activeTab,
  setActiveTab,
  activePlanType,
  setActivePlanType,
  implantsInfo
) => {
  return (
    <SurgeryPlanningInfo
      surgeryPlanning={data}
      surgeryMeasurements={surgeryMeasurements}
      operationSideValue={operationSide}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      setActivePlanType={setActivePlanType}
      activePlanType={activePlanType}
      implantsInfo={implantsInfo}
    />
  );
};

const getSectionValue = (section, values, functions) => {
  const caseInfo = values?.caseInfo;
  const patientValues = values?.patientValues;
  const sharedByInfo = values?.sharedByInfo;

  switch (section) {
    case CASE_DETAILS_SECTIONS.SURGERY_DATE:
      return getSurgeryDateValue(caseInfo.date, caseInfo.isDeclined);
    case CASE_DETAILS_SECTIONS.PATIENT:
      return getPatientValue(
        patientValues.Name,
        patientValues.MRN,
        patientValues.Gender,
        patientValues.BirthDate,
        patientValues.Age
      );
    case CASE_DETAILS_SECTIONS.SHARED_BY:
      return getSharedByValue(sharedByInfo.username, sharedByInfo.emailAddress);
    case CASE_DETAILS_SECTIONS.SURGERY:
      return getSurgeryValue(caseInfo.operationSide, caseInfo.classification);
    case CASE_DETAILS_SECTIONS.CT_SCANS:
      return getCtValue(values.ctScansPreview, values.ctScansStatus);
    case CASE_DETAILS_SECTIONS.NOTES:
      return getNotesValue(caseInfo.surgeryNote);
    case CASE_DETAILS_SECTIONS.PLANNING:
      return getPlanningValue(
        values.planningInfo.data,
        caseInfo.surgeryMeasurements,
        caseInfo.operationSide,
        values.activeTab,
        functions.setActiveTab,
        values.activePlanType,
        functions.setActivePlanType,
        values.implantsInfo
      );
    default:
      getDefaultValue(values);
  }
};

const getAgeFromBirthdate = birthDate => {
  return birthDate && calculateAge(formatDate(birthDate)).toString();
};

const checkInvalidCase = surgeryCase => {
  return (
    !surgeryCase.status ||
    surgeryCase.status === REQUEST_STATUS.ERROR ||
    (surgeryCase.status === REQUEST_STATUS.SUCCESS && !surgeryCase.data)
  );
};

const CaseDetails = ({
  history,
  surgeryCase,
  loadedCaseInfo,
  planningInfo,
  getCaseInfo,
  getPlanningInfo,
  getCaseTicketList,
  caseTickets,
  getCTScanPreview,
  setCTScanPreview,
  setCTScanPreviewError,
  ctScansStatus,
  ctScansPreview,
  resetCTScanPreviewStore,
  implantsInfo,
  loadImplantsInfo,
  getSharedByInfo,
  sharedByInfo,
  sharedByStatus,
  clearCaseInfo,
  clearPlanningInfo,
  websocketUpdate,
  websocketClearMessage
}) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [caseInfo, setCaseInfo] = useState(loadedCaseInfo);
  const [patientValues, setPatientValues] = useState([]);
  const [activeTab, setActiveTab] = useState("0");
  const [activePlanType, setActivePlanType] = useState("0");
  const [supportFileAvailable, setSupportFileAvailable] = useState(false);
  const [ticketJiraInfo, setTicketJiraInfo] = useState({
    caseId: null,
    ticketId: null,
    status: null
  });

  const params = useParams();

  useEffect(() => {
    const clearStorage = () => {
      clearCaseInfo();
      clearPlanningInfo();
      resetCTScanPreviewStore();
    };

    clearStorage();
    window.addEventListener("beforeunload", clearStorage);

    return () => {
      window.removeEventListener("beforeunload", clearStorage) ||
        clearStorage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadImplantsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadedCaseInfo && setCaseInfo(loadedCaseInfo);
  }, [loadedCaseInfo]);

  useEffect(() => {
    if (params.caseNumber) {
      getCaseInfo(params.caseNumber);
      getSharedByInfo(params.caseNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    const caseNumberUpdate = websocketUpdate?.message?.caseNumber;
    const caseStatusUpdate = websocketUpdate?.message?.status;

    if (caseNumberUpdate && caseNumberUpdate === caseInfo.caseNumber) {
      const updatedCaseInfo = { ...caseInfo, status: caseStatusUpdate };
      setCaseInfo(updatedCaseInfo);
      websocketClearMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocketUpdate]);

  useEffect(() => {
    if (
      !isDataLoaded &&
      surgeryCase.status === REQUEST_STATUS.SUCCESS &&
      !isEmpty(caseInfo)
    ) {
      setPatientValues({
        Name: caseInfo.patient.fullName,
        MRN: caseInfo.medicalRecordNumber,
        Gender: capitalize(caseInfo.patient.gender),
        "Date of birth": parseDateFormat(caseInfo.patient.birthDate),
        BirthDate: formatDate(caseInfo.patient.birthDate),
        Age: getAgeFromBirthdate(caseInfo.patient.birthDate)
      });

      getCaseTicketList(caseInfo.id);

      getPlanningInfo(caseInfo.surgeryPlannings?.references);

      setSupportFileAvailable(
        !checkIsLastFileDownloaded(
          caseInfo.caseNumber,
          caseInfo.surgeryCaseFiles
        ) && checkIsLastFileManualUpload(caseInfo.surgeryCaseFiles)
      );

      loadCTScansPreview(
        surgeryCase,
        caseInfo,
        getCTScanPreview,
        setCTScanPreview,
        setCTScanPreviewError
      );
      setIsDataLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseInfo]);

  useEffect(() => {
    updateTicketJiraInfo(caseTickets, setTicketJiraInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseTickets]);

  useEffect(() => {
    setActiveTab(selectActiveTab(planningInfo.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planningInfo.status]);

  const surgeryDateTitle = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
      }}
    >
      <span>Surgery date</span>
      {caseInfo.isDeclined && (
        <Tag
          className="declined"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          Declined
        </Tag>
      )}
    </div>
  );

  const getCardContent = section => {
    if (
      surgeryCase.status === REQUEST_STATUS.SUCCESS &&
      sharedByStatus === REQUEST_STATUS.SUCCESS &&
      (section !== CASE_DETAILS_SECTIONS.PLANNING ||
        (planningInfo.status === REQUEST_STATUS.SUCCESS && implantsInfo))
    ) {
      return getSectionValue(
        section,
        {
          caseInfo,
          patientValues,
          planningInfo,
          ctScansPreview,
          ctScansStatus,
          activeTab,
          implantsInfo,
          sharedByInfo,
          activePlanType
        },
        { setActiveTab, setActivePlanType }
      );
    } else if (
      !surgeryCase.status ||
      surgeryCase.status === REQUEST_STATUS.ERROR
    ) {
      return (
        <Spin
          className={`spinner ${section}-empty`}
          indicator={<ExclamationCircleOutlined />}
        />
      );
    } else {
      return (
        <Spin
          className={`spinner ${section}-empty`}
          indicator={<LoadingOutlined />}
        />
      );
    }
  };

  return (
    <Layout>
      <div className="header-container">
        <Header
          title={`Case #${checkValue(caseInfo.caseNumber)}`}
          titleSidedChildren={
            <Tooltip title="Copy">
              <CopyOutlined
                onClick={async () => {
                  await copyToClipboard(
                    caseInfo.caseNumber,
                    "Case number",
                    message
                  );
                }}
              />
            </Tooltip>
          }
        />
        {sharedByInfo && (
          <Tooltip title="Shared case">
            <SharedCase className="shared-case-icon" />
          </Tooltip>
        )}
        {getCloseButton(history)}
      </div>
      <Content>
        {checkInvalidCase(surgeryCase) ? (
          <div className="case-not-found">
            <InfoCard title="Case not found." cardClass>
              <p>
                <CloseCircleFilled />
                This case is not available. Please try with another case.
              </p>
            </InfoCard>
          </div>
        ) : (
          <div className="casedetail-layout">
            <div className="container container-columns">
              <div>
                {caseInfo.status &&
                  caseTickets.status === requestStatus.SUCCESS && (
                    <CaseStatusCard
                      caseStatus={caseInfo.status}
                      ticketJiraInfo={ticketJiraInfo}
                      supportFileAvailable={supportFileAvailable}
                      measurementsState={caseInfo.surgeryMeasurements?.state}
                      caseTickets={caseTickets}
                    />
                  )}
                <CaseStageCard
                  surgeryCaseState={caseInfo.state}
                  surgeryDaySelected={caseInfo.date}
                />
                <InfoCard title={surgeryDateTitle}>
                  {getCardContent(CASE_DETAILS_SECTIONS.SURGERY_DATE)}
                </InfoCard>
                <InfoCard
                  title="Patient's information"
                  titleLinks={[
                    {
                      isText: true,
                      linkText: <WarningDisclaimer />
                    }
                  ]}
                >
                  {getCardContent(CASE_DETAILS_SECTIONS.PATIENT)}
                </InfoCard>
                {sharedByInfo && (
                  <InfoCard title="Shared By">
                    {getCardContent(CASE_DETAILS_SECTIONS.SHARED_BY)}
                  </InfoCard>
                )}
              </div>
              <div>
                <InfoCard
                  title="Surgery data"
                  titleLinks={[
                    {
                      isText: true,
                      linkText: <WarningDisclaimer />
                    }
                  ]}
                >
                  {getCardContent(CASE_DETAILS_SECTIONS.SURGERY)}
                </InfoCard>
                <InfoCard
                  cardClass
                  title={<React.Fragment>2D Scans</React.Fragment>}
                  titleLinks={[
                    {
                      isText: true,
                      linkText: caseInfo.expirationDate
                        ? parseDateFormatFullYear(caseInfo.expirationDate)
                        : "Unavailable date"
                    }
                  ]}
                >
                  {getCardContent(CASE_DETAILS_SECTIONS.CT_SCANS)}
                </InfoCard>
                <InfoCard
                  title="Notes"
                  titleLinks={[
                    {
                      isText: true,
                      linkText: <WarningDisclaimer />
                    }
                  ]}
                  isLast
                >
                  {getCardContent(CASE_DETAILS_SECTIONS.NOTES)}
                </InfoCard>
              </div>
              <div className="planning-infocard">
                <InfoCard title="3D surgery planning">
                  {getCardContent(CASE_DETAILS_SECTIONS.PLANNING)}
                </InfoCard>
                <div className="logo-container">
                  <img
                    src={PoweredByLogo}
                    className="powered-by-logo"
                    alt="Powered by Provoyance Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Content>
    </Layout>
  );
};

CaseDetails.propTypes = {
  history: PropTypes.object,
  surgeryCase: PropTypes.object,
  loadedCaseInfo: PropTypes.object,
  planningInfo: PropTypes.object,
  getCaseInfo: PropTypes.func,
  clearCaseInfo: PropTypes.func,
  clearPlanningInfo: PropTypes.func,
  getPlanningInfo: PropTypes.func,
  caseTickets: PropTypes.object,
  getCaseTicketList: PropTypes.func,
  getCTScanPreview: PropTypes.func,
  setCTScanPreview: PropTypes.func,
  setCTScanPreviewError: PropTypes.func,
  ctScansStatus: PropTypes.string,
  ctScansPreview: PropTypes.object,
  resetCTScanPreviewStore: PropTypes.func,
  implantsInfo: PropTypes.object,
  loadImplantsInfo: PropTypes.func,
  getSharedByInfo: PropTypes.func,
  sharedByInfo: PropTypes.object,
  sharedByStatus: PropTypes.string,
  websocketUpdate: PropTypes.object,
  websocketClearMessage: PropTypes.func
};

const mapStateToProps = state => {
  const ctScan = state.cases.ctScan;
  return {
    surgeryCase: state.cases,
    loadedCaseInfo: state.cases.data,
    planningInfo: state.plannings,
    caseTickets: state.tickets,
    ctScansStatus: ctScan && ctScan.ctScanStatus,
    ctScansPreview: ctScan && ctScan.ctScansPreview,
    implantsInfo: state.plannings.implantsInfo,
    sharedByInfo: state.sharedCaseInfo.sharedByAttributes,
    sharedByStatus: state.sharedCaseInfo.sharedByStatus,
    websocketUpdate: state.websocket
  };
};

export default connect(mapStateToProps, {
  getCaseInfo,
  clearCaseInfo,
  clearPlanningInfo,
  getPlanningInfo,
  getCaseTicketList,
  getCTScanPreview,
  setCTScanPreview,
  setCTScanPreviewError,
  resetCTScanPreviewStore,
  loadImplantsInfo,
  getSharedByInfo,
  websocketClearMessage
})(CaseDetails);
