import React from "react";
import BaseButton from "../common/BaseButton";
import {
  BUTTON_CONSTS,
  DEMO_CT_SCANS,
  DOWNLOADS_INFO,
  USER_MANUAL_NAME
} from "../../helpers/consts";
import { getDownloadUrl, getFile } from "../../helpers/URLHelper";
import DownloadLink from "../common/DownloadLink";
import "./AdditionalMaterial.less";

const AdditionalMaterial = () => {
  return (
    <div className="additional-material">
      <label>Additional material</label>
      <a rel="noopener noreferrer" download className="user-manual-link">
        <BaseButton
          config={{
            type: BUTTON_CONSTS.TYPE.DEFAULT,
            size: BUTTON_CONSTS.SIZE.DEFAULT,
            icon: "file-pdf"
          }}
          text="User manual"
          onClick={async () =>
            await getFile(getDownloadUrl(USER_MANUAL_NAME), "_blank")
          }
        />
      </a>
      <a rel="noopener noreferrer" download>
        <BaseButton
          config={{
            type: BUTTON_CONSTS.TYPE.DEFAULT,
            size: BUTTON_CONSTS.SIZE.DEFAULT,
            icon: "file-zip"
          }}
          text="Demo CT Scans"
          onClick={async () => await getFile(getDownloadUrl(DEMO_CT_SCANS))}
        />
      </a>
      <label className="videos-title">Videos</label>
      <div className="videos-wrapper">
        {DOWNLOADS_INFO.map(downloadLinkInfo => {
          return (
            <DownloadLink
              key={downloadLinkInfo.downloadUrl}
              {...downloadLinkInfo}
            />
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(AdditionalMaterial);
