import React from "react";
import { BUTTON_CONSTS } from "../../helpers/consts";
import { getInstallerUrl, getFile, PLATFORM } from "../../helpers/URLHelper";
import userService from "../../helpers/userService";
import ProvoyanceLogo from "../../resources/layout/provoyance-logo.png";
import BaseButton from "../common/BaseButton";
import NewFeaturesList from "./NewFeaturesList";
import "./Welcome.less";

const Welcome = () => {
  return (
    <div className="portal-main-content">
      <label className="welcome-label">Welcome, {userService.getName()}</label>
      <div className="download-content">
        <div className="download-children">
          <div className="provoyance-shoulder-logo">
            <img src={ProvoyanceLogo} alt="provoyance-shoulder-logo" />
          </div>
          <p>
            ProVoyance&trade; Shoulder allows surgeons to pre-operatively plan a
            shoulder arthroplasty using available implants from Shoulder
            Innovations. 3D models of the humerus and scapula are generated by
            ProVoyance from patient imaging which allows surgeons to select the
            surgery type, desired implant sizes and locations for a specific
            patient condition.
            <br />
            <br />
            Download the latest version of the application and other material
            such as the user manual, sample scans and videos by clicking on the
            download links below.
          </p>
          <NewFeaturesList />
          <div className="download-buttons">
            <a rel="noopener noreferrer" download>
              <BaseButton
                config={{
                  type: BUTTON_CONSTS.TYPE.PRIMARY,
                  size: BUTTON_CONSTS.SIZE.DEFAULT
                }}
                text="Download for Windows"
                onClick={async () =>
                  await getFile(getInstallerUrl(PLATFORM.WINDOWS))
                }
              />
            </a>
            <a rel="noopener noreferrer" download>
              <BaseButton
                config={{
                  type: BUTTON_CONSTS.TYPE.PRIMARY,
                  size: BUTTON_CONSTS.SIZE.DEFAULT
                }}
                text="Download for Mac"
                onClick={async () =>
                  await getFile(getInstallerUrl(PLATFORM.MAC))
                }
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Welcome);
