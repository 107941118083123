import React from "react";
import PropTypes from "prop-types";
import { PlayCircleOutlined } from "@ant-design/icons";
import { BUTTON_CONSTS } from "../../helpers/consts";
import BaseButton from "./BaseButton";
import { getFile } from "../../helpers/URLHelper";
import "./DownloadLink.less";

const DownloadLink = ({ downloadUrl, text }) => {
  return (
    <div className="download-link">
      <PlayCircleOutlined />
      <a rel="noopener noreferrer" download>
        <BaseButton
          config={{
            type: BUTTON_CONSTS.TYPE.LINK,
            size: BUTTON_CONSTS.SIZE.DEFAULT
          }}
          text={text}
          onClick={async () => await getFile(downloadUrl, "_blank")}
        />
      </a>
    </div>
  );
};

DownloadLink.propTypes = {
  downloadUrl: PropTypes.string,
  text: PropTypes.string
};

export default React.memo(DownloadLink);
